import './App.css';
import { useEffect } from "react";
import { isMobile } from 'react-device-detect';

function App() {
  useEffect(() => {
      console.log(window.location)
      const currentUrl = new URL(window.location.href)
      const desktopParam = currentUrl.searchParams.get('desktop');
      if (!isMobile && desktopParam) {
          window.location.href = desktopParam;
      }
  }, [ ]);
  return (
    <div className="App">
        <div className="Content">
            <p className="Paragraph">
                <span className="Title">Klondike: The Lost Expedition</span> is
                a web browser game. in order to support the creator during the
                campaign time, please visit this page from your PC or MAC.
            </p>
        </div>
    </div>
  );
}

export default App;
